import isbot from "isbot";

import {CarbonEnvName} from "../../constants/urls";
import {isCI} from "../env";
import {isProd} from "../isProd";

const SERVICE = "app-analytics";
const BASE = "carbonhealth.com";
const ENDPOINT = "/v1/events";

const analyticsUrls = {
  [CarbonEnvName.PROD]: `https://${SERVICE}.${BASE}${ENDPOINT}`,
  [CarbonEnvName.ALPHA]: `https://${SERVICE}.staging.${BASE}${ENDPOINT}`,
} as const;

export const getAnalyticsEndpoint = () =>
  !isCI && !isbot(navigator.userAgent) && isProd ? analyticsUrls.prod : analyticsUrls.alpha;
