import {useEffect} from "react";

import {sfCenterPos} from "../components/_common/_constants";
import {WorkerUrl} from "../constants/workerUrls";
import {Coordinate} from "../store/types";
import {getLatLong, setLatLong} from "../utils/browser-storage/latLong";

const setDefaultLatLong = () => {
  setLatLong(sfCenterPos);
  return sfCenterPos;
};

type CfGeolocateResponse = {
  location: {
    lat: number;
    lng: number;
  };
};

const cfGeolocate = async (): Promise<Coordinate> => {
  try {
    const response = await fetch(`${WorkerUrl.PROD}/geolocation`, {
      method: "POST",
    });
    const position = await response.json().then((data: CfGeolocateResponse) => ({
      x: data.location.lat,
      y: data.location.lng,
    }));

    setLatLong(position);

    return position;
  } catch {
    return setDefaultLatLong();
  }
};

export const geolocateUser = async (forceCfGeolocate = false): Promise<Coordinate> =>
  forceCfGeolocate ? cfGeolocate() : getLatLong() || cfGeolocate();

export const useGeolocateUser = () => {
  useEffect(() => {
    geolocateUser(false);
  }, []);
};
